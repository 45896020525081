import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Cell, Legend, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { Empty, Spin } from 'antd';
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { deleteDoc, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import IntlMessages from 'util/IntlMessages';
import { auth, functions, db } from 'firebase/firebase';
import { ALLOWED_ROLES } from 'packages/utils';

import moment from 'moment';
import { SlidePanelContext } from 'packages/dashboard/components/SlidePanel/SlidePanelContext';
import { camelize, getRange } from '../../../../utils';
import { chartProptype, filtersProptype } from '../../../../../utils/proptypes/dashboard/pieChart';
import DashboardCard from '../DashboardCard';
import { COMPONENTS_OF_METRICS } from '../../constants';

const colorPalette = [
  '#3f8dd8',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#A28DFF',
  '#FF6B6B',
  '#6D597A',
  '#4CAF50',
  '#FFD700',
  '#FF1493',
  '#20B2AA',
  '#B22222',
];

const PieChartCard = ({ chart, onEdit, refresh, filters }) => {
  const { queryParams, title, id } = chart;

  const intl = useIntl();
  const { boardSelected } = useContext(SlidePanelContext);

  const chartGroupBy = filters.groupBy;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    metrics: [],
    info: {
      totalEvents: 0,
      division: {},
      eventType: {},
      trigger: {},
    },
  });

  const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
  let ownDivisionsCount = useSelector(({ divisions }) => divisions.assigned.data.divisions || {});
  ownDivisionsCount = Object.entries(ownDivisionsCount).length;
  const allDivisionSelected = selectedDivs.length >= ownDivisionsCount;

  const orgId = useSelector(state => state.organizations.organization.id);
  const addMessage = () => httpsCallable(functions, 'functions-eventsCounter-eventsCounter');

  useEffect(() => {
    const isValidRequest = !!orgId && !!selectedDivs.length;
    if (!isValidRequest) {
      return;
    }

    const { formId, eventIds } = queryParams;
    const {
      dateRange,
      relative: { dateType, relativeAmount, isRelative },
    } = filters;

    // Date parsed to manage the getRange
    const fromByDefault = moment().subtract(7, 'days');

    let dateRangeFormated = dateRange || [fromByDefault, moment()];
    if (dateRange?.length === 2) {
      dateRangeFormated = [moment(dateRange[0]), moment(dateRange[1])];
    }

    const paramsSent = {
      organizationId: orgId,
      divisionIds: (() => {
        if (boardSelected?.divId) {
          return [boardSelected.divId];
        }
        if (allDivisionSelected) {
          return [];
        }
        return selectedDivs;
      })(),
      interval: chartGroupBy,
      range: getRange(dateRangeFormated, dateType, isRelative, relativeAmount),
      uid: auth.currentUser.uid,
      formIds: [formId],
      eventTypes: eventIds,
    };

    setLoading(true);
    addMessage()(paramsSent)
      .then(async result => {
        const { data: agg } = camelize(result);
        const hits = agg?.hits || {};
        const totalEvents = hits.total?.value ?? 0;
        const extraInfo = hits?.hits[0]?.source;

        const formatedData = agg.aggregations.eventsPerTrigger.buckets
          .filter(({ docCount }) => docCount > 0)
          .map(({ triggerName, docCount }, index) => ({
            name: triggerName.hits?.hits[0]?.source?.trigger?.name,
            value: docCount,
            color: colorPalette[index % colorPalette.length],
          }));
        setData({
          metrics: formatedData,
          info: {
            totalEvents,
            division: extraInfo?.division || {},
            eventType: extraInfo?.eventType || {},
            trigger: extraInfo?.trigger || {},
          },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    orgId,
    selectedDivs,
    intl,
    queryParams,
    refresh,
    allDivisionSelected,
    filters,
    chartGroupBy,
    boardSelected.divId,
  ]);

  const handleEdit = () => {
    onEdit(chart, COMPONENTS_OF_METRICS.PIE_CHART);
  };

  const [getComposedPng, { ref, isLoading: downloadingPng }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    const png = await getComposedPng();
    if (png) {
      const noSpacesTitle = title.replaceAll(' ', '_');
      FileSaver.saveAs(png, `${noSpacesTitle}.png`);
    }
  }, [getComposedPng, title]);

  const handleDelete = () => {
    // The boardSelected has the information of the board selected
    let docRef = doc(db, 'organizations', orgId, 'dashboards', boardSelected.id, 'pie_charts', id);
    if (boardSelected?.divId) {
      docRef = doc(
        db,
        'organizations',
        orgId,
        'divisions',
        boardSelected.divId,
        'dashboards',
        boardSelected.id,
        'pie_charts',
        id,
      );
    }

    deleteDoc(docRef);
  };

  const renderLegendText = (value, entry) => {
    const {
      color,
      payload: { value: total },
    } = entry;

    const text = `${value}: ${total}`;
    return <span style={{ color }}>{text}</span>;
  };

  return (
    <DashboardCard
      title={title}
      subTitle={
        <IntlMessages id="dashboards.chart.totalEvents" values={{ total: data.info.totalEvents }} />
      }
      actionButtons={[
        {
          iconName: 'edit',
          action: handleEdit,
          type: 'secondary',
          allowedRole: [
            ...ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.UPDATE,
            ...ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CHARTS.UPDATE,
          ],
          iconSize: 'sm',
        },
        {
          iconName: 'download',
          action: handleDownload,
          type: 'secondary',
          iconSize: 'sm',
          disabled: downloadingPng,
        },
        {
          iconName: 'delete',
          action: handleDelete,
          type: 'danger',
          allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.DELETE,
          iconSize: 'sm',
        },
      ]}
    >
      {!loading && !!data.metrics.length && (
        <ResponsiveContainer width="100%" height="100%" minHeight={155} className="cancelDrag">
          <PieChart ref={ref}>
            <Pie dataKey="value" isAnimationActive={false} data={data.metrics} fill="#8884d8">
              {data.metrics.map(metric => (
                <Cell key={`cell-${metric.name}`} fill={metric.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend
              align="right"
              layout="horizontal"
              iconType="plainline"
              formatter={renderLegendText}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
      {loading && <Spin size="large" />}
      {!loading && !data.metrics.length && (
        <Empty
          className="gx-m-auto"
          description={<IntlMessages id="components.empty.description" />}
        />
      )}
    </DashboardCard>
  );
};

PieChartCard.propTypes = {
  chart: chartProptype.isRequired,
  onEdit: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  filters: filtersProptype.isRequired,
};

export default PieChartCard;
