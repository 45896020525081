import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FormSelect from 'components/Form/components/FormSelect';
import useGetEventTypes from 'packages/utils/hooks/useGetEventTypes';
import { SlidePanelContext } from 'packages/dashboard/components/SlidePanel/SlidePanelContext';
import {
  getPieChartByDivision,
  getPieChartByOrg,
} from 'util/firebase-operations/dashboards/charts/get';
import { DASHBOARDS_FORMS, LAYOUT_BY_DEFAULT } from '../../constants';
import DropDownTriggers from '../../../../../../containers/DropDownTriggers';
import { EventsByScheduleContext } from '../../EventsByScheduleContext';

const PieChartForm = ({ formRef, chartId, divId, boardId }) => {
  const intl = useIntl();
  const orgId = useSelector(state => state.organizations.organization.id);
  const { boardSelected } = useContext(SlidePanelContext);
  const { schedulesLoading, getEventTypesFromSchedules } = useContext(EventsByScheduleContext);

  const [formId, setFormId] = useState();
  const [eventIds, setEventIds] = useState([]);

  const [currentChart, setCurrentChart] = useState({});

  const [loading, setLoading] = useState(false);

  const formTypesFromSchedule = getEventTypesFromSchedules(formId, divId);

  const handleClear = () => {
    setFormId(null);
    setEventIds([]);

    formRef.current.resetFields();
  };

  const { data: formTypes, loading: formTypesLoading } = useGetEventTypes(
    true,
    boardSelected?.divId,
  );

  const mainLoading = schedulesLoading || formTypesLoading || loading;

  const divisionPerFormType = formTypes?.find(form => form.id === formId)?.divId || null;

  const onChangeEvents = useCallback(
    events => {
      const newEventsSelected = events.map(e => e.id);
      formRef.current.setFieldValue('eventIds', newEventsSelected);
      setEventIds(newEventsSelected);
    },
    [formRef],
  );

  const buildBody = useCallback(() => {
    const title = formRef.current.getFieldValue('title');
    const currentFormId = formRef.current.getFieldValue('formId');

    const body = {
      queryParams: {
        formId: currentFormId,
        eventIds,
      },
      layout: currentChart?.layout ?? LAYOUT_BY_DEFAULT,
      title,
    };
    if (formRef.current) {
      formRef.current.setFieldValue('chartFormData', body);
      formRef.current.setFieldValue('id', chartId);
    }
  }, [currentChart, formRef, chartId, eventIds]);

  useEffect(() => {
    if (chartId && boardId) {
      const fetchData = async () => {
        setLoading(true);
        const res = divId
          ? await getPieChartByDivision({ orgId, divId, boardId, chartId })
          : await getPieChartByOrg({ orgId, boardId, chartId });

        if (res) setCurrentChart(res);
        setLoading(false);
      };
      fetchData();
    }
  }, [boardId, divId, orgId, chartId]);

  useEffect(() => {
    if (Object.values(currentChart).length > 0) {
      const { title, queryParams } = currentChart;
      const { eventIds: eventsIdsFetched = [], formId: formIdFetched = {} } = queryParams;

      formRef.current.setFieldsValue({
        title,
        formId: formIdFetched,
        eventIds: eventsIdsFetched,
        id: chartId,
      });
      setEventIds(eventsIdsFetched);
      setFormId(formIdFetched);
    }
  }, [chartId, currentChart, formRef]);

  return (
    <Form
      onFieldsChange={buildBody}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      ref={formRef}
      name={DASHBOARDS_FORMS.PIE_CHART}
      initialValues={{
        id: chartId ?? null,
        title: formRef.current?.getFieldValue('title') ?? '',
        formId: formId ?? null,
        eventIds: eventIds ?? [],
        chartFormData: {},
      }}
    >
      <BoxContainer content loading={mainLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Title.LabelForm
              required
              className="gx-guarnic-pb-1"
              value={intl.formatMessage({
                id: 'dashboards.chart.form.title',
              })}
            />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="title"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} sm={8} xxl={8}>
            <Title.LabelForm required value={<IntlMessages id="dashboards.chart.form.form" />} />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="formId"
            >
              <FormSelect
                onChange={val => {
                  setEventIds([]);
                  formRef.current.setFieldValue('eventIds', []);

                  setFormId(val);
                }}
                options={formTypes}
                showSearch
                loading={formTypesLoading}
                value={formId}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} sm={8} xxl={8}>
            <Form.Item name="eventIds">
              <Title.LabelForm value={<IntlMessages id="general.eventTypes" />} />
              <DropDownTriggers
                mode="multiple"
                allowClear
                onChange={onChangeEvents}
                division={divisionPerFormType}
                eventType={formId}
                defaultValue={eventIds}
                value={eventIds}
                onClear={() => {
                  formRef.current.setFieldValue('eventIds', []);
                  setEventIds(null);
                }}
                extraTriggers={formTypesFromSchedule}
                disabled={!formId}
              />
            </Form.Item>
          </Col>
          <Col className="gx-flex-row gx-justify-content-end gx-mt-4" span={24}>
            <Button onClick={() => handleClear()}>
              <IntlMessages id="button.clear" />
            </Button>
          </Col>
        </Row>
      </BoxContainer>
      <Form.Item name="chartFormData" hidden />
      <Form.Item name="id" hidden />
    </Form>
  );
};

PieChartForm.propTypes = {
  formRef: PropTypes.object,
  chartId: PropTypes.object,
  divId: PropTypes.string,
  boardId: PropTypes.string,
};

export default PieChartForm;
