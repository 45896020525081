import React from 'react';
import { Form, Input } from 'antd';
import { pick } from 'lodash';

import IntlMessages from 'util/IntlMessages';

import SwitchInput from './components/SwitchInput';
import NumberInput from './components/NumberInput';
import SelectInput from './components/SelectInput';
import SelectOptions from './components/SelectOptions';

export const REDUCER_INDEX = 'eventTypes';
export const BASE_URL = 'eventTypes';

export const PATHS = {
  BASE_URL,
  CREATE_EVENT_TYPE: `${BASE_URL}/event_type/create/:atOrg?`,
  EDIT_EVENT_TYPE: `${BASE_URL}/event_type/edit/:eventTypeId/:divId?`,
};

export const getRouteToCreateEventType = (atOrg = '') =>
  PATHS.CREATE_EVENT_TYPE.replace(':atOrg?', atOrg);

export const getRouteToEditEventType = (eventTypeId, divId = '') =>
  PATHS.EDIT_EVENT_TYPE.replace(':eventTypeId', eventTypeId).replace(':divId?', divId);

export const MAX_COMPONENTS_PER_EVENT = 20;

export const COMPONENT_TYPES = {
  // CHECK: 'check',
  FILE: 'file',
  NUMBER: 'number',
  PHOTO: 'photo',
  TAG: 'tag',
  TEXT: 'text',
  TOGGLE: 'toggle',
  VIDEO: 'video',
  SELECT: 'select',
  DATE: 'date',
};

export const COMPONENT_PROPS = {
  AUTOCOMPLETE: 'autocomplete',
  CHECKED: 'checked',
  MAX_COUNT: 'maxCount',
  MAX_LENGTH: 'maxLength',
  MAX_VALUE: 'max',
  MIN_LENGTH: 'minLength',
  MIN_VALUE: 'min',
  PLACEHOLDER: 'placeholder',
  ORIGIN: 'origin',
  OPTIONS: 'options',
  INCLUDES_TIME: 'includesTime',
};

export const COMMON_PROPS = {
  REQUIRED: 'required',
};

export const TYPES_INTL = {
  // [COMPONENT_TYPES.CHECK]: 'eventTypes.component.check',
  [COMPONENT_TYPES.FILE]: 'eventTypes.component.file',
  [COMPONENT_TYPES.NUMBER]: 'eventTypes.component.number',
  [COMPONENT_TYPES.PHOTO]: 'eventTypes.component.photo',
  [COMPONENT_TYPES.TAG]: 'eventTypes.component.tag',
  [COMPONENT_TYPES.TEXT]: 'eventTypes.component.text',
  [COMPONENT_TYPES.TOGGLE]: 'eventTypes.component.toggle',
  [COMPONENT_TYPES.VIDEO]: 'eventTypes.component.video',
  [COMPONENT_TYPES.SELECT]: 'eventTypes.component.select',
  [COMPONENT_TYPES.DATE]: 'eventTypes.component.date',
};

export const PROPS_INTL = {
  [COMPONENT_PROPS.AUTOCOMPLETE]: 'eventTypes.component.componentProps.autocomplete',
  [COMPONENT_PROPS.CHECKED]: 'eventTypes.component.componentProps.checked',
  [COMPONENT_PROPS.MAX_COUNT]: 'eventTypes.component.componentProps.maxCount',
  [COMPONENT_PROPS.MAX_LENGTH]: 'eventTypes.component.componentProps.maxLength',
  [COMPONENT_PROPS.MAX_VALUE]: 'eventTypes.component.componentProps.maxValue',
  [COMPONENT_PROPS.MIN_LENGTH]: 'eventTypes.component.componentProps.minLength',
  [COMPONENT_PROPS.MIN_VALUE]: 'eventTypes.component.componentProps.minValue',
  [COMPONENT_PROPS.PLACEHOLDER]: 'eventTypes.component.componentProps.placeholder',
  [COMPONENT_PROPS.ORIGIN]: 'eventTypes.component.componentProps.origin',
  [COMPONENT_PROPS.OPTIONS]: 'eventTypes.component.componentProps.options',
  [COMPONENT_PROPS.INCLUDES_TIME]: 'eventTypes.component.componentProps.includesTime',
  [COMMON_PROPS.REQUIRED]: 'eventTypes.component.commonProps.required',
};

const switchInput = (id, fieldKey, extraProps, form, prop) => (
  <SwitchInput
    extraProps={extraProps}
    fieldKey={[fieldKey, prop]}
    form={form}
    key={`${fieldKey}-${prop}`}
    label={PROPS_INTL[prop]}
    name={[`${id}`, 'componentProps', prop]}
  />
);

const numberInput = (id, fieldKey, extraProps, form, prop, options) => (
  <NumberInput
    extraProps={extraProps}
    fieldKey={[fieldKey, prop]}
    form={form}
    key={`${fieldKey}-${prop}`}
    label={PROPS_INTL[prop]}
    name={[`${id}`, 'componentProps', prop]}
    options={{
      ...options,
      // If it has a depName, i add it ot the options
      ...(options.depName ? { dependencyName: [`${id}`, 'componentProps', options.depName] } : {}),
    }}
  />
);

/// ORIGIN
const SELECT_ORIGIN_DEFAULT = 'capture';
const ORIGIN_OPTIONS = {
  capture: 'eventTypes.component.componentProps.origin.capture',
  upload: 'eventTypes.component.componentProps.origin.upload',
  capture_upload: 'eventTypes.component.componentProps.origin.capture_upload',
};
const originIntl = Object.entries(ORIGIN_OPTIONS).reduce((acc, cur) => {
  const key = cur[1];
  const value = cur[0];
  return [
    ...acc,
    {
      label: key,
      value,
    },
  ];
}, []);

const selectOrigin = (id, fieldKey, extraProps, form, prop) => (
  <SelectInput
    extraProps={{
      ...extraProps,
    }}
    fieldKey={[fieldKey, prop]}
    form={form}
    key={`${fieldKey}-${prop}`}
    label={PROPS_INTL[prop]}
    name={[`${id}`, prop]}
    data={originIntl}
    defaultValue={SELECT_ORIGIN_DEFAULT}
  />
);
const selectOptions = (id, fieldKey, form, prop) => {
  const validateInputLength = async (_, value) =>
    new Promise((resolve, reject) => {
      if (value && value.length > 50) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(<IntlMessages id="eventTypes.component.componentProps.options.inputLength.error" />);
      } else {
        resolve();
      }
    });

  return (
    <SelectOptions
      key={`${fieldKey}-${prop}`} // This is used to avoid warnind relato to keys
      fieldKey={[fieldKey, prop]}
      name={[`${id}`, 'componentProps', prop]}
      label={PROPS_INTL[prop]}
      formOptions={[{ name: 'value', required: true, type: 'text' }]}
      limitOptions={10}
      form={form}
      isRequired
      extraRules={[{ validator: validateInputLength }]}
    />
  );
};

const PROPS_INPUTS = {
  [COMPONENT_PROPS.CHECKED]: (id, fieldKey, extraProps, form) =>
    switchInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.CHECKED),
  [COMPONENT_PROPS.AUTOCOMPLETE]: (id, fieldKey, extraProps, form) =>
    switchInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.AUTOCOMPLETE),
  [COMPONENT_PROPS.MAX_COUNT]: (id, fieldKey, extraProps, form) =>
    numberInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.MAX_COUNT, {
      min: 1,
      max: 10,
      required: true,
    }),
  [COMPONENT_PROPS.MAX_LENGTH]: (id, fieldKey, extraProps, form) =>
    numberInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.MAX_LENGTH, {
      depName: COMPONENT_PROPS.MIN_LENGTH,
    }),
  [COMPONENT_PROPS.MIN_LENGTH]: (id, fieldKey, extraProps, form) =>
    numberInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.MIN_LENGTH, {
      depName: COMPONENT_PROPS.MAX_LENGTH,
      checkMax: true,
    }),
  [COMPONENT_PROPS.MAX_VALUE]: (id, fieldKey, extraProps, form) =>
    numberInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.MAX_VALUE, {
      depName: COMPONENT_PROPS.MIN_VALUE,
    }),
  [COMPONENT_PROPS.MIN_VALUE]: (id, fieldKey, extraProps, form) =>
    numberInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.MIN_VALUE, {
      depName: COMPONENT_PROPS.MAX_VALUE,
      checkMax: true,
    }),
  [COMPONENT_PROPS.PLACEHOLDER]: (id, fieldKey, extraProps, form) => {
    const name = [id, 'componentProps', COMPONENT_PROPS.PLACEHOLDER];
    const isEditing = form.getFieldValue(['components', ...name]) !== undefined;
    const defaultProps = isEditing ? {} : { initialValue: '' };
    return (
      <Form.Item
        fieldKey={[fieldKey, COMPONENT_PROPS.PLACEHOLDER]}
        key={`${fieldKey}-${COMPONENT_PROPS.PLACEHOLDER}`}
        label={<IntlMessages id={PROPS_INTL[COMPONENT_PROPS.PLACEHOLDER]} />}
        name={name}
        {...defaultProps}
        {...extraProps}
      >
        <Input />
      </Form.Item>
    );
  },
  [COMPONENT_PROPS.ORIGIN]: (id, fieldKey, extraProps, form) =>
    selectOrigin(id, fieldKey, extraProps, form, COMPONENT_PROPS.ORIGIN),
  [COMPONENT_PROPS.OPTIONS]: (id, fieldKey, _, form) =>
    selectOptions(id, fieldKey, form, COMPONENT_PROPS.OPTIONS),
  [COMPONENT_PROPS.INCLUDES_TIME]: (id, fieldKey, extraProps, form) =>
    switchInput(id, fieldKey, extraProps, form, COMPONENT_PROPS.INCLUDES_TIME),
};

export const AVAILABLE_PROPS = {
  // [COMPONENT_TYPES.CHECK]: pick(PROPS_INPUTS, [COMPONENT_PROPS.CHECKED]),
  [COMPONENT_TYPES.FILE]: pick(PROPS_INPUTS, [COMPONENT_PROPS.MAX_COUNT]),
  [COMPONENT_TYPES.NUMBER]: pick(PROPS_INPUTS, [
    COMPONENT_PROPS.MIN_VALUE,
    COMPONENT_PROPS.MAX_VALUE,
    COMPONENT_PROPS.PLACEHOLDER,
  ]),
  [COMPONENT_TYPES.PHOTO]: pick(PROPS_INPUTS, [COMPONENT_PROPS.MAX_COUNT, COMPONENT_PROPS.ORIGIN]),
  [COMPONENT_TYPES.TAG]: pick(PROPS_INPUTS, [
    COMPONENT_PROPS.MIN_LENGTH,
    COMPONENT_PROPS.MAX_LENGTH,
    COMPONENT_PROPS.MAX_COUNT,
  ]),
  [COMPONENT_TYPES.TEXT]: pick(PROPS_INPUTS, [
    COMPONENT_PROPS.AUTOCOMPLETE,
    COMPONENT_PROPS.MIN_LENGTH,
    COMPONENT_PROPS.MAX_LENGTH,
    COMPONENT_PROPS.PLACEHOLDER,
  ]),
  [COMPONENT_TYPES.TOGGLE]: pick(PROPS_INPUTS, [COMPONENT_PROPS.CHECKED]),
  [COMPONENT_TYPES.VIDEO]: pick(PROPS_INPUTS, [COMPONENT_PROPS.MAX_COUNT, COMPONENT_PROPS.ORIGIN]),
  [COMPONENT_TYPES.SELECT]: pick(PROPS_INPUTS, [COMPONENT_PROPS.OPTIONS]),
  [COMPONENT_TYPES.DATE]: pick(PROPS_INPUTS, [COMPONENT_PROPS.INCLUDES_TIME]),
};
