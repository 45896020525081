import { createOperation } from 'util/firebase-operations/utils/createOperation';
import {
  ACTIONS,
  getRouteToChartByDivision,
  getRouteToChartByOrg,
  getRouteToNewBoardByDivision,
  getRouteToNewBoardByOrg,
  getRouteToTableByDivision,
  getRouteToTableByOrg,
  getRouteToPieChartByOrg,
  getRouteToPieChartByDivision,
} from '../../constants';

const createTableByOrg = async ({ data, orgId, boardId }) =>
  createOperation(getRouteToTableByOrg(orgId, boardId), null, data, ACTIONS.CREATE);

const createTableByDivision = async ({ data, orgId, divId, boardId }) =>
  createOperation(getRouteToTableByDivision(orgId, divId, boardId), null, data, ACTIONS.CREATE);

const createBoardByOrg = async ({ data, orgId }) =>
  createOperation(getRouteToNewBoardByOrg(orgId), null, data, ACTIONS.CREATE);

const createBoardByDivision = async ({ data, orgId, divId }) =>
  createOperation(getRouteToNewBoardByDivision(orgId, divId), null, data, ACTIONS.CREATE);

const createChartByOrg = async ({ data, orgId, boardId }) =>
  createOperation(getRouteToChartByOrg(orgId, boardId), null, data, ACTIONS.CREATE);

const createChartByDivision = async ({ data, orgId, divId, boardId }) =>
  createOperation(getRouteToChartByDivision(orgId, divId, boardId), null, data, ACTIONS.CREATE);

const createPieChartByOrg = async ({ data, orgId, boardId }) =>
  createOperation(getRouteToPieChartByOrg(orgId, boardId), null, data, ACTIONS.CREATE);

const createPieChartByDivision = async ({ data, orgId, divId, boardId }) =>
  createOperation(getRouteToPieChartByDivision(orgId, divId, boardId), null, data, ACTIONS.CREATE);

export {
  createBoardByOrg,
  createBoardByDivision,
  createChartByOrg,
  createChartByDivision,
  createTableByOrg,
  createTableByDivision,
  createPieChartByOrg,
  createPieChartByDivision,
};
