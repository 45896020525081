import React from 'react';

import classNames from 'classnames';

import IntlMessages from 'util/IntlMessages';
import { getProjectAccordingToDomain } from 'packages/utils';

import styles from './styles.module.less';

export default function AppBrand({ children, className, placement = 'vertical' }) {
  const brandValues = getProjectAccordingToDomain();

  const placementType = {
    horizontal: 'gx-flex-row gx-justify-content-center',
    vertical: 'gx-flex-column gx-align-items-center',
  }

  return (
    <div className={classNames(styles.titleContainer, className, placementType[placement])}>
      <div className={brandValues.project === 'operonda' ? styles.logo : styles.logoGuarnic}>
       <img src={brandValues.logo} alt={brandValues.title} />
      </div>
      <div className="gx-guarnic-headline-1">
        {children ?? (
          <IntlMessages
            id="auth.login.to"
            values={{ text: brandValues.title }}
          />
        )}
      </div>
    </div>
  );
}
