import { doc, getDoc } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import {
  DASHBOARD_EVENTS_TABLES,
  DASHBOARDS,
  DIVISIONS,
  ORGANIZATIONS,
  PIE_CHARTS,
} from '../../constants';

const getTableByOrg = async ({ orgId, boardId, tableId }) => {
  const workOrderRef = doc(
    db,
    ORGANIZATIONS,
    orgId,
    DASHBOARDS,
    boardId,
    DASHBOARD_EVENTS_TABLES,
    tableId,
  );

  return getDoc(workOrderRef).then(res => {
    if (res.exists()) {
      return res.data();
    }

    return null;
  });
};

const getTableByDivision = async ({ orgId, divId, boardId, tableId }) => {
  const workOrderRef = doc(
    db,
    ORGANIZATIONS,
    orgId,
    DIVISIONS,
    divId,
    DASHBOARDS,
    boardId,
    DASHBOARD_EVENTS_TABLES,
    tableId,
  );

  return getDoc(workOrderRef).then(res => {
    if (res.exists()) {
      return res.data();
    }

    return null;
  });
};

const getPieChartByOrg = async ({ orgId, boardId, chartId }) => {
  const workOrderRef = doc(db, ORGANIZATIONS, orgId, DASHBOARDS, boardId, PIE_CHARTS, chartId);

  return getDoc(workOrderRef).then(res => {
    if (res.exists()) {
      return res.data();
    }

    return null;
  });
};

const getPieChartByDivision = async ({ orgId, divId, boardId, chartId }) => {
  const workOrderRef = doc(
    db,
    ORGANIZATIONS,
    orgId,
    DIVISIONS,
    divId,
    DASHBOARDS,
    boardId,
    PIE_CHARTS,
    chartId,
  );

  return getDoc(workOrderRef).then(res => {
    if (res.exists()) {
      return res.data();
    }

    return null;
  });
};

export { getTableByOrg, getTableByDivision, getPieChartByOrg, getPieChartByDivision };
